"use client";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/src/components/ui/accordion";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/src/components/ui/carousel";
import Image from "next/image";
import Link from "next/link";
import { Suspense } from "react";
import { FAQ } from "../components/faq/FAQAccordion";
import LandingVideo from "../components/landingVideo/landingVideo";
import { StickyScroll } from "../components/ui/sticky-scroll";
import EmailCapture from "./emailCapture";
import Footer from "./footer";
import Header from "./header";
import "./styles.scss";
// import posthog from "posthog-js";
import AutoplayVideo from "./autoplayVideo";
import Marquee from "react-fast-marquee";
import { AboutHeader } from "./about/aboutHeader";
import { CTARow } from "./ctaRow";
import AppAndPlayStore from "./app_and_play_store";

// posthog.init("phc_XbaVMJxYBOdYd6i6Ti3AOvAXQpLSLE1cQtvWi7hodfC", { api_host: "https://app.posthog.com" });

interface HowToVideoProps {
  video: string;
}

const HowToVideo: React.FC<HowToVideoProps> = ({ video }) => {
  return (
    // <div className="relative mx-auto overflow-hidden rounded-[30px] border-b-[5px] border-l-[5px] border-r-[5px] border-t-[5px] border-black bg-black sm:rounded-[40px] lg:rounded-[40px] lg:border-b-[10px] lg:border-l-[10px] lg:border-r-[10px] lg:border-t-[10px] 2xl:rounded-[60px]">
    <div className="md:px-0 px-8 mx-auto my-auto md:h-[377px] w-[606px] select-none lg:block">
      <video
        autoPlay
        loop
        muted
        playsInline // This attribute is important for autoplay on iOS devices
        className="h-full w-full rounded-[28px] object-cover"
      >
        <source src={`/video/${video}.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    // </div>
  );
};

const EmailCaptureFallback = () => {
  return (
    <>
      <div className="[ email_capture disable-flow ]">
        <input
          className="[ input email_input ss-large ] [ text-16 round ]"
          type="text"
          maxLength={80}
          required={true}
          placeholder={"Enter your email address"}
        //disabled={i.disabled}
        />
        <button
          className="[ button width-50 email_button width-40 ] [ text-16 ]"
          data-variant="gradient"
          data-size="thin"
        >
          Start for Free
        </button>
      </div>
    </>
  );
};

const content = [
  {
    title: "10x your visibility for buyers",
    description:
      "Toggle to sell your items across leading marketplaces, including Facebook, eBay, and Mercari. Maximize your visibility and reach more potential buyers effortlessly.",
    content: (
      <div className="h-full w-full inline-flex items-center relative">
        <Image
          src="/mockupsToggle.png"
          width={320}
          height={720}
          className="h-full w-full object-cover"
          alt="Toggle marketplaces"
        />
        <div className="absolute top-[144px] left-[16px] w-[290px] h-[700px] z-10 overflow-hidden rounded-[28px]">
          <AutoplayVideo
            video="toggle"
            props="w-full h-full object-cover rounded-[42px]"
            size={625}
          />
        </div>
      </div>
    ),
    icon: "/visibility.svg",
  },
  {
    title: "Shipping made convenient",
    description:
      "Coordinating shipping is tedious. We'll pick up your packages for delivery from the comfort of home.",
    content: (
      <div className="h-full w-full flex items-center justify-center text-white">
        <Image
          src="/mockupsShipping.png"
          width={320}
          height={720}
          className="h-full w-full object-cover"
          alt="Convenient shipping"
        />
      </div>
    ),
    icon: "/box.svg",
  },
  {
    title: "Talk to buyers from one app",
    description:
      "Avoid repetitive, unwarranted messages and low-ballers. Manage buyers across all of your marketplaces from a single, intuitive interface.",
    content: (
      <div className="h-full w-full flex items-center justify-center text-white">
        <Image
          src="/mockupsBuyers.png"
          width={320}
          height={720}
          className="h-full w-full object-cover"
          alt="Manage buyers"
        />
      </div>
    ),
    icon: "/crossPlatform.svg",
  },
];

const Landing = () => {
  return (
    <div className="overflow-x-none">
      <Header />
      <main className="[ xpadding margin-top nosel ]">
        <div className="container mx-auto px-8 lg:px-8 xl:px-12 max-w-7xl">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-16">
            <div className="w-full lg:w-1/2 max-w-[487px] font-[590] mt-8 lg:mt-0">
              <div className="inline-flex">
                <Link
                  href={"/blog/sellraze-annouces-560k-pre-seed-round-to-revolutionze-2nd-hand-selling"}
                  className=""
                >
                  <AboutHeader arrow={true} text="SellRaze raises $560K from Cory Levy, Chris Klaus"></AboutHeader>
                </Link>
              </div>

              <div className="mt-5 flex w-full flex-col bg-clip-text text-5xl lg:text-7xl leading-tight lg:leading-[72px] text-black">
                <div>
                  <span className="header font-sans font-bold text-[#111111]">Sell your items</span>
                  <div className="flex-row">
                    <span className="header font-sans font-bold text-[#111111]"> in</span>
                    <span className="header fg-blue-gradient-dark font-sans font-bold"> seconds.</span>
                  </div>
                </div>
              </div>

              <div className="mt-5 w-full text-base tracking-[-0.02em] text-zinc-950 text-opacity-70">
                <span className="font-sans text-xl lg:text-[24px] font-[500] leading-normal tracking-[-0.01em] text-zinc-950">
                  Make money without the headaches of selling.{" "}
                </span>
                <span className="fg-min-grey font-sans text-xl lg:text-[24px] font-[400] leading-normal tracking-[-0.02em]">
                  List across your favorite marketplaces with a tap.
                </span>
              </div>

              <LandingVideo></LandingVideo>

              <AppAndPlayStore />
            </div>
            <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
              <AutoplayVideo video="landingPageAnimation" size={625} />
            </div>
          </div>
        </div>
        <div className="mx-auto mb-1 flex w-full justify-center text-center font-sans">
          POWERING THOUSANDS OF SELLERS ON
        </div>
        <section className="mx-auto mb-6 mt-0 max-w-screen-lg opacity-65 ">
          <Marquee gradient gradientWidth={25} className="h-[75px]" speed={45} autoFill loop={0}>
            <Image
              draggable={false}
              src="/integrations/amazon.svg"
              className="mx-4 h-[24px] w-[78px] "
              priority
              width="78"
              height="24"
              alt="amazon"
            />
            <Image
              draggable={false}
              src="/integrations/poshmark.svg"
              className="mx-4 h-[24px]  w-[138px] "
              width="138"
              height="24"
              alt="poshmark"
              priority
            />
            <Image
              draggable={false}
              src="/integrations/grailed.svg"
              className="mx-4 h-[24px]  w-[193px] "
              width="193"
              height="24"
              alt="grailed"
              priority
            />
            <Image
              draggable={false}
              src="/integrations/vinted.svg"
              className="mx-4 h-[24px]  w-[73px] "
              width="73"
              height="24"
              alt="vinted"
              priority
            />
            <Image
              draggable={false}
              src="/integrations/depop.svg"
              className="mx-4 h-[24px]  w-[95px] "
              width="95"
              height="24"
              alt="depop"
              priority
            />
            <Image
              draggable={false}
              src="/integrations/ebay.svg"
              className="mx-4 h-[24px]  w-[69px] "
              width="69"
              height="24"
              alt="ebay"
              priority
            />
            <Image
              draggable={false}
              src="/integrations/mercari.svg"
              className="mx-4 h-[24px]  w-[119px] "
              width="119"
              height="24"
              alt="mercari"
              priority
            />
            <Image
              draggable={false}
              src="/integrations/facebook.svg"
              className="mx-4 h-[24px]  w-[100px] "
              width="100"
              height="24"
              alt="facebook"
              priority
            />
          </Marquee>
        </section>
        {/* How it works Section */}
        <div className="container mx-auto px-8 lg:px-8 xl:px-12 max-w-7xl py-24">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-16 mb-6 pt-10">
            <div className="w-full lg:w-1/2">
              <section className="flex flex-col items-start justify-between">
                <h2 className="font-sans text-4xl md:text-5xl lg:text-6xl font-bold tracking-[-0.02em] text-[#111111] mb-12">
                  How it works
                </h2>
                {[1, 2, 3].map((step, index) => (
                  <div key={step} className={`mb-12 ${index !== 0 ? 'mt-12' : ''}`}>
                    <div className="flex aspect-square h-[50px] w-[50px] items-center justify-center rounded-full bg-white drop-shadow-2xl mb-6">
                      <span className="bg-clip-text font-sans font-bold text-blue-600">{step}</span>
                    </div>
                    <h3 className="font-sans text-2xl md:text-3xl font-bold tracking-[-0.02em] text-zinc-950 mb-4">
                      {step === 1 && "Snap a picture or scan a barcode."}
                      {step === 2 && "Describe your item's condition."}
                      {step === 3 && "List, then ship."}
                    </h3>
                    <p className="font-sans text-lg md:text-xl font-[510] tracking-[-0.02em] text-zinc-950 text-opacity-70 max-w-[446px]">
                      {step === 1 && "We'll autofill the listing description, title, and category."}
                      {step === 2 && "We'll calculate the market value of your item."}
                      {step === 3 && "Tap to toggle listings on all of your favorite marketplaces instantly."}
                    </p>
                  </div>
                ))}
              </section>
            </div>
            <div className="w-full lg:w-1/2 flex items-center justify-center">
              <HowToVideo video="SellRazeExplainer" />
            </div>
          </div>
        </div>

        <section className="w-full bg-[#FAFAFA]">
          <StickyScroll content={content} />
        </section>


        {/* Cards Section */}
        <section className="margin-inline-auto flow-large-landing flex justify-center bg-[#F3F3F6] py-24">
          <div className=" flex h-full max-w-[1520px] flex-col items-start justify-start gap-10">
            <div className="bold px-12 w-full text-center font-sans text-[56px] tracking-[-0.02em] text-zinc-950 2xl:max-w-[1352px]">
              Selling has never been this easy.
            </div>
            <div className="mx-auto grid w-full grid-cols-1 items-center justify-start gap-10 px-4 lg:min-h-[330px] lg:grid-cols-2 xl:max-h-[330px] 2xl:max-w-[1352px]">
              <div className=" mx-auto flex h-full w-full max-w-[400px] flex-col items-start justify-start gap-[11px] rounded-[32px] border border-white bg-gradient-to-b from-blue-600 to-sky-400 p-10 shadow filter lg:max-w-full  xl:max-h-[330px] xl:flex-row 2xl:min-h-[330px] 2xl:p-10">
                <div className="flex flex-col items-start justify-start gap-[11px] space-y-3">
                  <div className="max-w-[280px] font-sans text-[42px] font-bold leading-tight tracking-[-0.02em] text-white text-opacity-70">
                    Quality listings, fast.
                  </div>
                  <div className="max-w-[300px] font-sans text-2xl leading-normal tracking-tight text-white">
                    Go from image, text, or barcode to listing in seconds.
                  </div>
                </div>
                <div className="flex h-full align-center space-x-5">
                  <Image
                    draggable={false}
                    alt="img"
                    height={109}
                    width={131}
                    className="md:h-[190px] h-[225px] w-full"
                    src="/desc.png"
                  />
                </div>
              </div>
              <div className=" relative mx-auto flex h-full w-full max-w-[400px] flex-col items-center justify-start gap-[11px] rounded-[32px] border border-white bg-white p-10 filter lg:max-w-full xl:max-h-[330px] xl:flex-row 2xl:min-h-[330px] 2xl:p-10">
                <div className="flex flex-col items-start justify-start gap-[11px]">
                  <div className="max-w-[329px]  font-sans text-[42px] font-bold leading-tight tracking-tight text-black">
                    Ship quickly
                  </div>
                  <div className="max-w-[299px] font-sans text-2xl leading-normal tracking-tight text-black">
                    When your product sells, we&apos;ll make you a label. Schedule a pickup, or drop-off at USPS.
                  </div>
                </div>
                <div className="mx-auto flex h-[230px] justify-center">
                  <Image
                    draggable={false}
                    height={310}
                    width={207.15}
                    alt="img"
                    className="absolute bottom-0 mx-auto flex h-[244px] w-[224px] rounded"
                    src="/fulfill.png"
                  />
                </div>
              </div>
            </div>
            <div className="mx-auto grid w-full grid-cols-1 items-center justify-start gap-10 px-4 filter  lg:grid-cols-3 xl:max-h-[555px] 2xl:min-h-[555px] 2xl:max-w-[1352px]">
              <div className=" mx-auto flex h-full w-full max-w-[407px] flex-col items-center justify-start gap-[11px] rounded-[32px] border border-white bg-gradient-to-b from-[#60A0FF] to-[#D9E8FF] p-10 2xl:p-10">
                <div className="inline-flex flex-col items-start justify-start gap-[11px]">
                  <div className="font-sans text-[52px] font-bold leading-tight tracking-tight text-[#00428A] text-opacity-100">
                    List across all platforms.
                  </div>
                  <div className="max-w-[320px] font-sans text-[24px] leading-normal text-white">
                    Toggle your favorite marketplaces and we&apos;ll take care of the rest.
                  </div>
                </div>
                <div className="relative h-56 w-[291px]">
                  <Image
                    draggable={false}
                    alt="img"
                    height={224}
                    width={291}
                    className="h-[224px] w-[291px] rounded-[22px]  "
                    src="https://imagedelivery.net/f1AE2Y7V_QnQya9C5OVynQ/7cc81784-b2f7-4f19-1686-611c91070f00/public"
                  />
                </div>
              </div>
              <div className=" mx-auto flex h-full w-full max-w-[400px] flex-col items-center justify-between gap-[11px] rounded-[32px] border border-white bg-white p-10 filter  2xl:min-h-[555px]  2xl:p-10">
                <div className="inline-flex flex-col items-start justify-start gap-[11px]">
                  <div className="max-w-[329px] font-sans text-[52px] font-bold leading-[110%] tracking-tight text-black">
                    Itemize your inventory
                  </div>
                  <div className="max-w-[299px] font-sans text-[24px] leading-normal text-black">
                    Determine the value of your items instantly.
                  </div>
                </div>
                <div className="bg-gradient-to-r from-black to-[#646464] relative rounded-xl w-full border-[3px] border-[#00000066]">
                  <Image
                    src="/sellraze_white_logo.png"
                    alt="SellRaze Logo"
                    width={180}
                    height={180}
                    className="absolute right-0 bottom-0 opacity-30 z-5"
                  />
                  <div className="px-4 flex flex-row justify-between items-center mt-3">
                    <div className="py-3 w-full">
                      <p className="text-[18px] text-white text-center font-sans font-medium tracking-[-0.3px]">
                        Your gross inventory value
                      </p>
                      <p className="text-white text-center font-sans font-bold text-[36px] tracking-[-0.18px]">
                        $1494.50
                      </p>
                    </div>
                  </div>
                  <div className="px-4 py-2.5 z-20 flex flex-row justify-around bg-black w-full rounded-b-lg">
                    <div className="flex flex-col items-center mr-2 justify-center">
                      <span className="text-white font-sans font-semibold text-[16px] tracking-[-0.3px]">
                        14 items
                      </span>
                      <span className="text-white font-sans font-normal text-[12px]">
                        total
                      </span>
                    </div>
                    <div className="flex flex-col items-center mx-2 justify-center">
                      <span className="text-white font-sans font-semibold text-[16px] tracking-[-0.3px]">
                        6 items
                      </span>
                      <span className="text-white font-sans font-normal text-[12px]">
                        listed
                      </span>
                    </div>
                    <div className="flex flex-col items-center ml-2 justify-center">
                      <span className="text-white font-sans font-semibold text-[16px] tracking-[-0.3px]">
                        $1194.20
                      </span>
                      <span className="text-white font-sans font-normal text-[12px]">
                        in unsold value
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" relative mx-auto flex h-full min-h-[500px] w-full max-w-[400px] flex-col items-center justify-start gap-[11px] rounded-[32px] border border-white bg-gradient-to-r from-blue-600 to-sky-400  p-10 filter 2xl:p-10">
                <div className="flex flex-col items-start justify-start gap-[11px]">
                  <div className="max-w-[329px]  font-sans text-[52px] font-bold leading-none tracking-tight text-white text-opacity-70">
                    Refer + earn more
                  </div>
                  <div className="max-w-[299px] font-sans text-[24px] leading-normal tracking-tight text-white">
                    Earn rewards and additional commision by inviting your friends.
                  </div>
                </div>
                <Image
                  draggable={false}
                  alt="img"
                  height={288}
                  width={323}
                  className="absolute bottom-0 mx-auto  h-[288px] w-[327px] rounded-t-[22px] "
                  src="/phone2.png"
                />
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="flex-col mx-auto max-w-[800px] px-[16px]">
          <div className="my-14 px-4 text-center font-sans text-[48px] font-semibold leading-[52px] tracking-[-0.02em] text-zinc-950">
            Frequently asked questions
          </div>
          {/* <FAQAccordion faqs={faqs} /> */}
          <Accordion
            type="single"
            collapsible
            className="w-full rounded-[21px] bg-[#FAFAFA] font-sans drop-shadow-xl "
          >
            {faqs.map((faq, index) => (
              <AccordionItem
                key={index}
                value={`item-${index}`}
                className={`border-b border-gray-200 px-6 ${index === faqs.length - 1 ? " rounded-b-[21px]" : ""} `}
              >
                <AccordionTrigger className="text-left font-sans text-lg text-zinc-950 ">
                  {faq.question}
                </AccordionTrigger>
                <AccordionContent className="font-sans text-base font-normal leading-normal text-slate-500">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </section>
        {/* User Testimonials */}
        <section className=" flow-large-landing mx-2 items-center py-24">
          {/* <section className="[ width-text margin-inline-auto text-column flow flex-col align-center] "> */}
          <div className="flex w-[min(140ch,100%)] mx-auto mb-24 user-select-none justify-between px-4 py-8 lg:mb-[2rem]">
            <div className="my-14 px-4 text-center font-sans text-[48px] font-semibold tracking-[-0.02em] text-zinc-950 ">
              See what early users are saying.
            </div>
            <div
              className=" align-center landing-video mt-3 cursor-pointer justify-end gap-[6px] px-4 lg:px-0"
            // onClick={() => {
            //   window.location.href = "mailto:hello@sellraze.com";
            // }}
            >
              <p
                className="bg-clip-text text-transparent bg-blue-gradient-dark cursor-pointer font-sans"
                onClick={() => {
                  window.location.href =
                    "https://docs.google.com/forms/d/e/1FAIpQLSc6iul3h6ak4PsqEiRg0yK9jqk2BWtueh0Bz8V9zQSR_rgdGA/viewform?usp=sf_link";
                }}
              >
                Share your feedback
              </p>
              <Image
                draggable={false}
                className="cursor-pointer"
                src="/chevronGradient.svg"
                width="9"
                height="14"
                alt=""
                onClick={() => {
                  window.location.href =
                    "https://docs.google.com/forms/d/e/1FAIpQLSc6iul3h6ak4PsqEiRg0yK9jqk2BWtueh0Bz8V9zQSR_rgdGA/viewform?usp=sf_link";
                }}
                priority
              />
            </div>
          </div>
          <Carousel
            opts={{
              align: "start",
              loop: true,
            }}
            className="svg-fix mx-auto w-full select-none flex-row sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-2xl"
          >
            <CarouselContent className="-ml-2">
              {reviews.map((rev, index) => (
                <CarouselItem
                  key={index}
                  className="w-full pl-0 drop-shadow-md sm:basis-1/2 lg:basis-1/3 xl:basis-1/4 2xl:basis-1/5"
                >
                  <div className="flex justify-center">
                    <div className="inline-flex h-[380px] w-[280px] flex-col items-start justify-start rounded-[32px] border bg-neutral-100 pb-8 pl-8 pr-8">
                      <div className=" flex  h-full flex-col items-start justify-between gap-2.5">
                        <div className="font-sans text-[18px] text-base leading-normal text-[#080C10] text-opacity-100">
                          <br />
                          {rev.content}
                        </div>
                        <div className="mb-2 inline-flex w-[193px] flex-col items-start justify-start gap-2.5 ">
                          <Image
                            draggable={false}
                            alt="User Image"
                            className="h-[42px] w-[42px] rounded-[49px]"
                            height={42}
                            width={42}
                            src={rev.img}
                          />
                          <div>
                            <div className="font-sans text-xl font-semibold text-zinc-950">{rev.name}</div>
                            <div className="font-sans font-medium text-zinc-950">{rev.position}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="mr-10" />
            <CarouselNext className="mr-10" />
          </Carousel>
        </section>
        {/* CTA Section */}
        <CTARow />
      </main>
      <Footer />
    </div>
  );
};

export default Landing;

const faqs: FAQ[] = [
  {
    question: "How do I get started with selling on SellRaze?",
    answer:
      "Download the SellRaze app from the Apple App Store or Google Play, enter your phone number, and follow the guided setup to list your first item for sale. Our user-friendly interface simplifies the listing process to make getting started fast and easy.",
  },
  {
    question: "Can I list items on SellRaze if I'm new to online selling?",
    answer:
      "Absolutely! SellRaze is designed for sellers of all experience levels. We offer step-by-step guidance and tips for creating effective listings that sell quickly and maximize your profit.",
  },
  {
    question: "What is the process for listing an item on SellRaze?",
    answer:
      "Snap a photo, fill in the details with our prompts, set a price, and publish. Our AI streamlines this process, making it intuitive and quick—your listing goes live in seconds.",
  },
  {
    question: "How does listing on multiple marketplaces work with SellRaze?",
    answer:
      "SellRaze seamlessly lists your items on a network of top-rated seller accounts, ensuring maximum visibility and swift sales. Experience fast, lucrative selling like never before.",
  },
  {
    question: "How much are the fees with SellRaze?",
    answer:
      "SellRaze offers competitive fees tailored to maximize your profits. We charge a minimal commission only when you make a sale, ensuring our interests are aligned with your success.",
  },
  {
    question: "How can I make my listings stand out?",
    answer:
      "Enhance your listings with high-quality photos and detailed descriptions. Our AI recommendations help optimize your listings for visibility and appeal, making them stand out to potential buyers.",
  },
  {
    question: "What if I'm too busy to manage my listings?",
    answer:
      "SellRaze's automated features streamline your sales. Schedule listings, use our AI to auto-fill details, and let our smart notifications keep you updated effortlessly, saving you time.",
  },
  // {
  //   question: "Is it possible to sell internationally with SellRaze?",
  //   answer: "Absolutely. SellRaze supports global sales, offering tools for currency conversion, international shipping options, and localized market insights to sell your items worldwide.",
  // },
  {
    question: "How do I deal with shipping and packaging?",
    answer:
      "SellRaze simplifies shipping by offering prepaid labels and guiding you to affordable packaging solutions. Ship confidently with our streamlined process, designed for seller ease.",
  },
];
const reviews = [
  {
    name: "Braden",
    position: "New to selling online",
    content: (
      <div>
        {`"Before I found SellRaze I had never sold an item online. I was able to create my first listing in 2
        minutes and my item sold almost immediatly. This is a game changer"`}
      </div>
    ),
    img: "/customers/braden.png",
  },
  {
    name: "Carter",
    position: "Regular reseller",
    content: (
      <div>
        {`"I have already made enough sales on eBay to cover the cost of SellRaze + made another $50 and it's only been a few days.
        Well worth the download. So much to love about the product!"`}
      </div>
    ),
    img: "/customers/carter.png",
  },
  {
    name: "Leo",
    position: "Novice seller",
    content: (
      <div>
        {`"As a reseller on multiple platforms, SellRaze has transformed my business. It allows me to identify, price, and crosslist in a few seconds!
        Listing used to take me hours. SellRaze saved me so much time and effort. I love it!"`}
      </div>
    ),
    img: "/customers/leo.png",
  },
  {
    name: "@AndySellsOnline",
    position: "Casual Seller",
    content: (
      <div>
        {`"I've sold my clothes on Poshmark, Mercari and Depop for years. I have now expanded my operations to several new marketplaces 
        with no additional effort. The speed with support is also incredible! Love the SellRaze team."`}
      </div>
    ),
    img: "/customers/miki.png",
  },
  {
    name: "Sean",
    position: "College Student",
    content: (
      <div>
        {`"I've never even thought about selling online, being in school has made it impossible. Now I want to
        sell every day, sellraze makes it so easy to make money, its the best app I've ever used"`}
      </div>
    ),
    img: "/customers/sean.png",
  },
  {
    name: "Tyler",
    position: "Full time seller",
    content: (
      <div>
        {`"Wow. I've sold on every marketplace you can imagine. SellRaze has boosted my business by 130% in the
        last month so I can focus on sourcing inventory and not listing."`}
      </div>
    ),
    img: "/customers/tyler.png",
  },
  {
    name: "Vik",
    position: "Full Time Student",
    content: (
      <div>
        {`"Between classes and homework, I've never had time to sell my old clothes. Now that I have SellRaze I
        cleaned out my entire closet and made $234 in 4 days. Must have for college students"`}
      </div>
    ),
    img: "/customers/vik.png",
  },
  {
    name: "Jeff",
    position: "Full Time eBay Seller",
    content: (
      <div>
        {`"I source my inventory from pallet's & garage sales. SellRaze allows me to list my items 10x faster
        than before. It's boosted my business's revenue by 34% in the last month."`}
      </div>
    ),
    img: "/customers/jeff.png",
  },
];
